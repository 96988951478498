import React from "react";
import * as img from "../media/all-photo-gallery";

export default function Gallery({ t }) {
	let items = document.querySelectorAll(".carousel .carousel-item");

	items.forEach((elem) => {
		const minPerSlide = 4;
		let next = elem.nextElementSibling;
		for (var i = 1; i < minPerSlide; i++) {
			if (!next) {
				next = items[0];
			}
			let cloneChild = next.cloneNode(true);
			elem.appendChild(cloneChild.children[0]);
			next = next.nextElementSibling;
		}
	});

	return (
		<section id="gallery" className="gallery sections-white">
			<div className="container">
				<div className="section-header pb-4 pb-lg-2">
					<h2>{t("gallery.title")}</h2>
					<p>{t("gallery.description")}</p>
				</div>

				<div className="row g-4 ">
					<div className="container text-center my-3">
						<div
							className="row mx-auto my-auto justify-content-center"
							style={{ height: "100%", alignContent: "center" }}
						>
							<div
								id="galleryCarousel"
								className="carousel slide"
								data-bs-ride="carousel"
							>
								<div className="carousel-inner" role="listbox">
									<div className="carousel-item active">
										<div className="col-md-4 col-lg-3 container-photo">
											<div className="card">
												<div className="card-img">
													<img
														src={img.photoMani}
														className="img-fluid"
														alt="consegna chiavi"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="col-md-4 col-lg-3 container-photo">
											<div className="card">
												<div className="card-img">
													<img
														src={img.photo1}
														className="img-fluid"
														alt="auto al chiuso"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="col-md-4 col-lg-3 container-photo">
											<div className="card">
												<div className="card-img">
													<img
														src={img.photo2}
														className="img-fluid"
														alt="posto auto chiuso"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="col-md-4 col-lg-3 container-photo">
											<div className="card">
												<div className="card-img">
													<img
														src={img.photo3}
														className="img-fluid"
														alt="auto in uscita"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="col-md-4 col-lg-3 container-photo">
											<div className="card">
												<div className="card-img">
													<img
														src={img.photo4}
														className="img-fluid"
														alt="parcheggio visto dall'esterno"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="col-md-4 col-lg-3 container-photo">
											<div className="card">
												<div className="card-img">
													<img src={img.photo5} className="img-fluid" alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<a
									className="carousel-control-prev carousel-control bg-transparent w-auto"
									href="#galleryCarousel"
									role="button"
									data-bs-slide="prev"
								>
									<span aria-hidden="true">
										<i className="fi fi-br-angle-left"></i>
									</span>
								</a>
								<a
									className="carousel-control-next carousel-control bg-transparent w-auto"
									href="#galleryCarousel"
									role="button"
									data-bs-slide="next"
								>
									<span aria-hidden="true">
										<i className="fi fi-br-angle-right"></i>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
