import React from "react";
import * as img from "../media/all-photo-gallery";

export default function CTA({ t }) {
	return (
		<section id="CTA" className="py-0">
			<div className="cover">
				<div className="section-header section-header-blue">
					<h2 className="text-white">{t("header.benefits.four")}</h2>
					<p>{t("cta.text")}</p>
				</div>
				<div className="container position-relative">
					<div className="row gy-4 pb-4 justify-content-center">
						<div className="col-6 col-lg-3 d-flex flex-column align-items-center">
							<div
								style={{
									height: "160px",
									width: "160px",
									background: `url(${img.porto})`,
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									borderRadius: "50%",
								}}
								className="mb-4"
							></div>
							<h4 className="text-center">{t("cta.destination_one.title")}</h4>
							<div>
								<p className="description-destination">
									{t("cta.destination_one.text")}
								</p>
								<ul className="destinations-list">
									<li>Capraia</li>
									<li>Elba</li>
									<li>Corsica</li>
									<li>Gorgona</li>
									<li>{t("cta.destination_one.li4")}</li>
									<li>{t("cta.destination_one.li5")}</li>
								</ul>
							</div>
						</div>
						<div className="col-6 col-lg-3 d-flex flex-column align-items-center">
							<div
								style={{
									height: "160px",
									width: "160px",
									background: `url(${img.airport})`,
									backgroundPosition: "50%",
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									borderRadius: "50%",
								}}
								className="mb-4"
							></div>
							<h4 className="text-center">{t("cta.destination_two.title")}</h4>
							<div>
								<p className="description-destination">
									{t("cta.destination_two.text")}
								</p>
								<ul className="destinations-list">
									<li>Amsterdam</li>
									<li>{t("cta.destination_two.li2")}</li>
									<li>Dubai</li>
									<li>{t("cta.destination_two.li4")}</li>
									<li>{t("cta.destination_two.li5")}</li>
									<li>{t("cta.destination_two.li6")}</li>
									<li>Madrid</li>
									<li>New York</li>
									<li>{t("cta.destination_two.li9")}</li>
								</ul>
							</div>
						</div>
						<div className="col-6 col-lg-3 d-flex flex-column align-items-center">
							<div
								style={{
									height: "160px",
									width: "160px",
									background: `url(${img.stazione})`,
									backgroundPosition: "40%",
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									borderRadius: "50%",
								}}
								className="mb-4"
							></div>
							<h4 className="text-center">
								{t("cta.destination_three.title")}
							</h4>
							<div>
								<p className="description-destination">
									{t("cta.destination_three.text")}
								</p>
								<ul className="destinations-list">
									<li>{t("cta.destination_three.li1")}</li>
									<li>Forte dei Marmi</li>
									<li>Lucca</li>
									<li>Siena</li>
									<li>Arezzo</li>
								</ul>
							</div>
						</div>
						<div className="col-6 col-lg-3 d-flex flex-column align-items-center">
							<div
								style={{
									height: "160px",
									width: "160px",
									background: `url(${img.centroCitta})`,
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									borderRadius: "50%",
								}}
								className="mb-4"
							></div>
							<h4 className="text-center">
								{t("cta.destination_four.title1")} <br></br>
								{t("cta.destination_four.title2")}
							</h4>
							<div>
								<p className="description-destination">
									{t("cta.destination_four.text")}
								</p>
								<ul className="destinations-list">
									<li>Hotel</li>
									<li>{t("cta.destination_four.li2")}</li>
									<li>B&B</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
