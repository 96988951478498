import React from "react";
import { ferriesMap } from "../media/all-photo-gallery";
export default function FerriesMap({ t }) {
	return (
		<section id="ferriesMap" className="sections-white">
			<div className="container" data-aos="fade-up">
				<div className="section-header section-header-white">
					<h2>{t("ferries_map.title")}</h2>
					<p>{t("ferries_map.description")}</p>
				</div>
				<div className="container" data-aos="fade-up">
					<div className="row gx-lg-0 gy-4">
						<div className="col-xs-12 col-lg-10 mx-auto">
							<div className="content">
								<img src={ferriesMap} className="img-fluid" alt="ferries map" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
