import React from "react";
export default function Testimonials({ t }) {
	return (
		<section id="testimonials" className="sections-white">
			<div className="container" data-aos="fade-up">
				<div className="section-header section-header-white">
					<h2>{t("testimonials.title")}</h2>
					<p>{t("testimonials.description")}</p>
				</div>

				<div
					className="testimonials text-center"
					ata-aos="zoom-out"
					data-aos-delay="100"
				>
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col-md-6 col-lg-4">
								<div className="card border-light bg-light text-center">
									<i
										className="fi fi-bs-quote-right card-img-top rounded-circle quote"
										aria-hidden="true"
									></i>
									<div className="card-body blockquote">
										<div className="row">
											<div className="col-xs-12">
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
											</div>
										</div>

										<p className="card-text">{t("testimonials.alessia")}</p>
										<footer className="blockquote-footer">
											<cite title="Source Title">Alessia P.</cite>
										</footer>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="card border-light bg-light text-center">
									<i
										className="fi fi-bs-quote-right card-img-top rounded-circle quote"
										aria-hidden="true"
									></i>
									<div className="card-body blockquote">
										<div className="row">
											<div className="col-xs-12">
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
											</div>
										</div>
										<p className="card-text">{t("testimonials.enrico")}</p>
										<footer className="blockquote-footer">
											<cite title="Source Title">Enrico V.</cite>
										</footer>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="card border-light bg-light text-center">
									<i
										className="fi fi-bs-quote-right card-img-top rounded-circle quote"
										aria-hidden="true"
									></i>
									<div className="card-body blockquote">
										<div className="row">
											<div className="col-xs-12">
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
											</div>
										</div>
										<p className="card-text">{t("testimonials.massimo")}</p>
										<footer className="blockquote-footer">
											<cite title="Source Title">Massimo P.</cite>
										</footer>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="card border-light bg-light text-center">
									<i
										className="fi fi-bs-quote-right card-img-top rounded-circle quote"
										aria-hidden="true"
									></i>
									<div className="card-body blockquote">
										<div className="row">
											<div className="col-xs-12">
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
											</div>
										</div>
										<p className="card-text">{t("testimonials.annatalena")}</p>
										<footer className="blockquote-footer">
											<cite title="Source Title">Annatalena A.</cite>
										</footer>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="card border-light bg-light text-center">
									<i
										className="fi fi-bs-quote-right card-img-top rounded-circle quote"
										aria-hidden="true"
									></i>
									<div className="card-body blockquote">
										<div className="row">
											<div className="col-xs-12">
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
												<i className="fi fi-sr-star px-1 star"></i>
											</div>
										</div>
										<p className="card-text">{t("testimonials.davide")}</p>
										<footer className="blockquote-footer">
											<cite title="Source Title">Davide G.</cite>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
