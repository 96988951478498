import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./script/english.json";
import common_it from "./script/italian.json";
import { HashRouter } from "react-router-dom";

i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
	lng: localStorage.getItem("lng") || "it", // language to use
	resources: {
		en: {
			common: common_en, // 'common' is our custom namespace
		},
		it: {
			common: common_it,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<HashRouter>
			<I18nextProvider i18n={i18next}>{<App />}</I18nextProvider>
		</HashRouter>
	</React.StrictMode>
);
