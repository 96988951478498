import React from "react";

export function Maps({ t }) {
	return (
		<section id="location" className="sections-bg">
			<div className="container" data-aos="fade-up">
				<div className="section-header section-header-blue">
					<h2 className="text-white">{t("address.title")}</h2>
					<p>{t("address.description")}</p>
				</div>
				<div className="container" data-aos="fade-up">
					<div className="row gx-lg-0 gy-4">
						<div className="col-lg-5 col-xl-4" style={{ minHeight: "20rem" }}>
							<div className="content h-100">
								<iframe
									title="Maps Integration"
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2890.611196574721!2d10.340148415625075!3d43.57298407912422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d5eb8c5b6911f1%3A0x3e38ea858a42ec32!2sVia%20dei%20Materassai%2C%201%2C%2057121%20Livorno%20LI%2C%20Italia!5e0!3m2!1sit!2sde!4v1674117753495!5m2!1sit!2sde&zoom=9"
									className="w-100 h-100"
									zoom="8"
									style={{ border: 0 }}
									allowFullScreen=""
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</div>
						</div>

						<div className="col-lg-7 col-xl-8 ps-lg-4">
							<div>
								<h3 className="text-white">{t("address.sectionOneTitle")}</h3>
								<ul className="list-directions">
									<li>{t("address.sectionOneStepOne")}</li>
									<li>{t("address.sectionOneStepTwo")}</li>
									<li>{t("address.sectionOneStepThree")}</li>
									<li>{t("address.sectionOneStepFour")}</li>
									<li>{t("address.sectionOneStepFive")}</li>
									<li>{t("address.sectionOneStepSix")}</li>
								</ul>
								{/* <p className="text-white">

								
									Molestie a iaculis at erat pellentesque adipiscing commodo.
									Dignissim suspendisse in est ante in. Nunc vel risus commodo
									viverra maecenas accumsan. Sit amet nisl suscipit adipiscing
									bibendum est. Purus gravida quis blandit turpis cursus in
								</p> */}
							</div>
							<div style={{ marginTop: "40px" }}>
								<h3 className="text-white">{t("address.sectionTwoTitle")}</h3>
								<ul className="list-directions">
									<li>{t("address.sectionTwoStepOne")}</li>
									<li>{t("address.sectionTwoStepTwo")}</li>
									<li>{t("address.sectionTwoStepThree")}</li>
									<li>{t("address.sectionTwoStepFour")}</li>
									<li>{t("address.sectionTwoStepFive")}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
