import { useMediaQuery } from "react-responsive";

const MediaQuery = () => {
	const forEmail = useMediaQuery({ query: "(max-width: 390px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
	const isMobileMax = useMediaQuery({ query: "(max-width: 576px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
	const isNotDesktop = useMediaQuery({ query: "(max-width: 992px)" });

	return { forEmail, isMobile, isMobileMax, isTablet, isNotDesktop };
};

export default MediaQuery;
