import React from "react";
import * as img from "../media/all-photo-gallery";
import { Footer } from "./footer";
import MediaQuery from "../helpers/media-query";
import { Link } from "react-router-dom";

export default function Ferries({ t }) {
	function scrollDownToBenefitsHarbour() {
		const endingScroll = document.getElementById("harbourBenefits");
		document
			.getElementById("scrollButton")
			.addEventListener("click", function () {
				endingScroll.scrollIntoView({ behavior: "smooth" });
			});
	}
	const { forEmail } = MediaQuery();
	return (
		<>
			<section id="ferries" className="hero">
				<div className="cover">
					<div className="container main-info position-relative">
						<div className="row gy-5 h-100" data-aos="fade-in">
							<div className="col-lg-8 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start mt-0">
								<img src={img.logoNoBcg} alt="logo" />
								<h6>{t("ferries.hero.title")}</h6>
								<p>{t("ferries.hero.subtitle")}</p>
								<div className="d-flex justify-content-center justify-content-lg-start">
									<button
										to="/ferry&cruise#harbour"
										className="btn-get-started"
										id="scrollButton"
										onClick={scrollDownToBenefitsHarbour}
									>
										{t("benefits.cta")}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<main id="main">
				<section id="harbourBenefits">
					<div className="container" data-aos="fade-up">
						<div className="section-header">
							<h2>{t("ferries.benefits.title")}</h2>
							<p>{t("ferries.benefits.subtitle")}</p>
						</div>
						<div className="container position-relative pt-4 pb-4">
							<div className="row">
								<div className="d-flex container-benefits-list">
									<div className="benefits-list">
										<ul className="pb-4 pb-md-0">
											<li>{t("ferries.benefits.benefit1")}</li>
											<li>{t("ferries.benefits.benefit2")}</li>
											<li>{t("ferries.benefits.benefit3")}</li>
											<li>{t("ferries.benefits.benefit4")}</li>
											<li>{t("ferries.benefits.benefit5")}</li>
											<li>{t("ferries.benefits.benefit6")}</li>
										</ul>
									</div>
									<div className="boat-vertical col-12 col-md-4">
										<img src={img.cruiseBoatVert} alt="logo" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="services sections-bg">
					<div className="container" data-aos="fade-up">
						<div className="section-header section-header-blue">
							<h2 className="text-white">{t("ferries.location.title")}</h2>
							<p className="text-white">{t("ferries.location.subtitle")}</p>
						</div>
						<div className="row gx-lg-0 gy-4 d-flex flex-column">
							<div style={{ minHeight: "20rem" }}>
								<div className="content">
									<iframe
										title="Maps Integration"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2890.611196574721!2d10.340148415625075!3d43.57298407912422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d5eb8c5b6911f1%3A0x3e38ea858a42ec32!2sVia%20dei%20Materassai%2C%201%2C%2057121%20Livorno%20LI%2C%20Italia!5e0!3m2!1sit!2sde!4v1674117753495!5m2!1sit!2sde&zoom=9"
										className="w-100"
										zoom="8"
										style={{ border: 0, minHeight: "20rem" }}
										allowFullScreen=""
										loading="lazy"
										referrerPolicy="no-referrer-when-downgrade"
									></iframe>
								</div>
							</div>
							<div>
								<p className="h-100 ferries-text-line-height text-white">
									{t("ferries.location.text1")} <br />
									{t("ferries.location.text2")} <br />
									{t("ferries.location.text3")}
								</p>
							</div>
						</div>
					</div>
				</section>

				{/* <section id="ferries" className="py-0">
					<div className="cover">
						<div className="section-header section-header-blue container position-relative">
							<h2 className="text-white not-after-dash">
								Parcheggio Traghetti e Crociere Livorno
							</h2>
							<h4 className="subtitle">
								Parcheggia l’auto e parti per la tua crociera!
							</h4>
							
							<p>
								Se stai cercando parcheggi traghetto livorno, parcheggi porto
								livorno, parcheggi crociera livorno Parking &amp; Transfer
								Livorno fa al caso tuo!
							</p>
						</div>
						<div className="container position-relative pt-4 pb-4">
							<div className="row">
								<h4 className="text-center">
									Perché scegliere Parking &amp; Transfer Livorno?
								</h4>
								<div className="d-flex container-benefits-list">
									<div className="benefits-list">
										<ul className="pb-4 pb-md-0">
											<li>La Tariffa Settimanale è la più Vantaggiosa!</li>
											<li>Tutti i posti sono al Coperto</li>
											<li>
												Trasferimento rapido, privato e gratuito al Check-in
											</li>
											<li>Parcheggio a soli 10min dal Porto di Livorno</li>
											<li>
												Parcheggiando da noi eviti il traffico urbano, ti
												portiamo noi al tuo imbarco
											</li>
											<li>
												Nessun Anticipo alla Prenotazione paga in loco al tuo
												rientro
											</li>
											<li>Assistenza attiva 24 ore su 24!</li>
										</ul>
									</div>
									<div className="logo">
										<img src={img.logoNoBcg} alt="logo" />
									</div>
								</div>
							</div>

						</div>
					</div>
				</section> */}

				<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">
						<div className="section-header">
							<h2>{t("ferries.contact.title")}</h2>
							<p>{t("ferries.contact.subtitle")}</p>
						</div>

						<div className="row gx-lg-0 gy-4">
							<div className="col-lg-5 col-xl-4 mt-0">
								<div className="info-container d-flex flex-column align-items-center justify-content-center">
									<div className="info-item d-flex">
										<i className="bi bi-geo-alt flex-shrink-0"></i>
										<div>
											<h4>{t("contact.address")}:</h4>
											<p>Via dei materassai 1/b, Livorno 57121, Italia</p>
										</div>
									</div>
									{/* <!-- End Info Item --> */}

									<div className="info-item d-flex">
										<i className="bi bi-envelope flex-shrink-0"></i>
										<div>
											<h4>Email:</h4>
											{forEmail ? (
												<p>
													info@<br></br>
													<span>parkingtransferlivorno.com</span>
												</p>
											) : (
												<p>info@parkingtransferlivorno.com</p>
											)}
										</div>
									</div>
									{/* <!-- End Info Item --> */}

									<div className="info-item d-flex">
										<i className="bi bi-phone flex-shrink-0"></i>
										<div>
											<h4>{t("contact.phone")}:</h4>
											<p>+39 375 696 6969</p>
										</div>
									</div>
									{/* <!-- End Info Item --> */}

									<div className="info-item d-flex">
										<i className="bi bi-clock flex-shrink-0"></i>
										<div>
											<h4>{t("contact.hours")}:</h4>
											<p>24/7</p>
										</div>
									</div>
									{/* <!-- End Info Item --> */}
								</div>
							</div>
							<div className="col-lg-7 col-xl-8 mt-0">
								<div className="ferries-text-cta">
									<h4>{t("ferries.contact.title_text")}</h4>
									<div className="container-final-benefits">
										<p>{t("ferries.contact.text1")}</p>
										<p>{t("ferries.contact.text2")}</p>
										<p>{t("ferries.contact.text3")}</p>
										<p>{t("ferries.contact.text4")}</p>
										<p>{t("ferries.contact.text5")}</p>
									</div>
									<div className="text-center mt-4">
										<Link to="/home#contact">
											<button>{t("header.booking")}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>

			<Footer t={t} />
		</>
	);
}
