import React, { useRef, useState } from "react";
import IntPhoneInput from "./phone";
import emailjs from "@emailjs/browser";
import i18next from "i18next";

export function ContactForm({ activeSuccessModal, activeErrorModal, t }) {
	const [inquiryObject, setInquiryObject] = useState("generalInfo");
	const [destinationTransfer, setDestinationTransfer] = useState("");
	const [additionalServices, setAdditionalServices] = useState("");
	const form = useRef();

	const currentLanguage = !localStorage.getItem("lng")
		? i18next.language
		: localStorage.getItem("lng");

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_SERVICE,
				process.env.REACT_APP_TEMPLATE,
				form.current,
				process.env.REACT_APP_EMAILJS_USER_ID,
				{
					personalData: e.target.personalData.checked ? "checked" : "unchecked",
				}
			)
			.then(
				(result) => {
					activeSuccessModal.current.click();
					e.target.reset();
				},
				(error) => {
					activeErrorModal.current.click();
				}
			);
	};

	return (
		<>
			<div className="col-lg-7 col-xl-8">
				<form
					ref={form}
					onSubmit={sendEmail}
					method="post"
					className="php-email-form"
				>
					<div className="form-group mt-1">
						<select
							className="select-form-group form-control"
							aria-label="Select Subject"
							name="subject"
							onChange={(e) => setInquiryObject(e.target.value)}
							required
							defaultValue=""
						>
							<option hidden value="">
								{t("contact.fields.subject.placeholder")}
							</option>
							<option value="generalInfo" type="text" className="form-control">
								{t("contact.fields.subject.option_one")}
							</option>
							<option value="parking" type="text" className="form-control">
								{t("contact.fields.subject.option_two")}
							</option>
							<option
								value="parking&transfer"
								type="text"
								className="form-control"
							>
								{t("contact.fields.subject.option_three")}
							</option>
						</select>
						<label className="form__label">
							{" "}
							{t("contact.fields.subject.label")} *
						</label>
					</div>

					<div className="row">
						<div className="col-md-6 form-group">
							<input
								type="text"
								name="customer_name"
								placeholder={t("contact.fields.name.placeholder")}
								id="name"
								className="form-control"
								required
							/>
							<label className="form__label">
								{t("contact.fields.name.label")} *
							</label>
						</div>
						<div className="col-md-6 form-group mt-1 mt-md-0">
							<input
								type="text"
								className="form-control"
								name="customer_surname"
								id="surname"
								placeholder={t("contact.fields.surname.placeholder")}
							/>
							<label className="form__label">
								{t("contact.fields.surname.label")} *
							</label>
						</div>
					</div>
					<div className="row mt-1">
						<div className="col-md-6 form-group">
							<input
								type="email"
								className="form-control"
								name="customer_email"
								id="email"
								placeholder={t("contact.fields.email.placeholder")}
								required
							/>
							<label className="form__label">
								{t("contact.fields.email.label")}*
							</label>
						</div>
						<div className="col-md-6 form-group mt-1 mt-md-0">
							<IntPhoneInput name="customer_phone" />
							<label className="form__label">{t("contact.phone")}</label>
						</div>
					</div>
					{inquiryObject !== "generalInfo" && (
						<>
							<div className="row mt-1">
								<div className="col-md-6 form-group">
									<input
										className="form-control"
										type="date"
										placeholder=" "
										name="checkin_date"
										id="checkin"
										required
									/>
									<label className="form__label">Checkin *</label>
								</div>
								<div className="col-md-6 form-group mt-1 mt-md-0">
									<input
										className="form-control"
										type="date"
										placeholder=" "
										name="checkout_date"
										id="checkout"
										required
									/>
									<label className="form__label">Checkout *</label>
								</div>
							</div>
							<div className="row mt-1">
								<div className="col-md-6 form-group">
									<input
										className="form-control"
										type="time"
										placeholder=" "
										name="checkin_time"
										id="checkinTime"
										required
									/>
									<label className="form__label">Checkin Time *</label>
								</div>
								<div className="col-md-6 form-group mt-1 mt-md-0">
									<input
										className="form-control"
										type="time"
										placeholder=" "
										name="checkout_time"
										id="checkoutTime"
										required
									/>
									<label className="form__label">Checkout Time *</label>
								</div>
							</div>
							<div className="row mt-1 ">
								<div className="col-md-6 form-group">
									<select
										className="select-form-group form-control"
										aria-label="Select Vehicle"
										name="vehicle"
										required
										defaultValue=""
									>
										<option hidden value="">
											{t("contact.fields.select_vehicle.placeholder")}
										</option>
										<option
											value="Auto"
											type="text"
											className="form-control default-option-value"
										>
											{t("contact.fields.select_vehicle.option_one")}
										</option>
										<option value="Camper" type="text" className="form-control">
											{t("contact.fields.select_vehicle.option_two")}
										</option>
										<option value="Moto" type="text" className="form-control">
											{t("contact.fields.select_vehicle.option_three")}
										</option>
									</select>
									<label className="form__label">
										{t("contact.fields.select_vehicle.label")} *
									</label>
								</div>

								<div className="col-md-6 form-group">
									<select
										className={`select-form-group form-control ${
											additionalServices === "" ? "not-required" : ""
										}`}
										aria-label="Additional Services"
										name="additional_services"
										defaultValue=""
										onClick={(e) => setAdditionalServices(e.target.value)}
									>
										<option value="" hidden>
											{t("contact.fields.additional_services.placeholder")}
										</option>
										<option
											value="Vehicle Wash"
											type="text"
											className="form-control"
										>
											{t("contact.fields.additional_services.option_one")}
										</option>
										<option
											value="Mechanic Assistance"
											type="text"
											className="form-control"
										>
											{t("contact.fields.additional_services.option_two")}
										</option>
										<option
											value="Vehicle Wash + Mechanic Assistance"
											type="text"
											className="form-control"
										>
											{t("contact.fields.additional_services.option_three")}
										</option>
										<option value="None" type="text" className="form-control">
											{t("contact.fields.additional_services.option_four")}
										</option>
									</select>
									<label className="form__label">
										{t("contact.fields.additional_services.label")}
									</label>
								</div>
							</div>
						</>
					)}
					{inquiryObject === "parking&transfer" && (
						<>
							<div className="row mt-1">
								<div
									className={`form-group ${
										destinationTransfer !== "Aeroporto" &&
										destinationTransfer !== "Stazione"
											? "w-100"
											: ""
									}`}
								>
									<select
										className="select-form-group form-control"
										aria-label="Select Destination"
										name="destination"
										onChange={(e) => setDestinationTransfer(e.target.value)}
										required
										defaultValue=""
									>
										<option hidden value="">
											{t("contact.fields.transfer.placeholder")}
										</option>
										<option
											value="AeroportoPisa"
											type="text"
											className="form-control"
										>
											{t("contact.fields.transfer.option_one")}
										</option>
										<option
											value="AeroportoFlorence"
											type="text"
											className="form-control"
										>
											{t("contact.fields.transfer.option_two")}
										</option>
										<option value="Centro" type="text" className="form-control">
											{t("contact.fields.transfer.option_three")}
										</option>
										<option value="Porto" type="text" className="form-control">
											{t("contact.fields.transfer.option_four")}
										</option>
										<option
											value="Stazione"
											type="text"
											className="form-control"
										>
											{t("contact.fields.transfer.option_five")}
										</option>
										<option value="other" type="text" className="form-control">
											{t("contact.fields.transfer.option_six")}
										</option>
									</select>
									<label className="form__label">
										{t("contact.fields.transfer.label")} *
									</label>
								</div>

								{["Aeroporto", "Stazione"].includes(destinationTransfer) && (
									<div className="form-group ">
										<input
											className="form-control"
											type="time"
											placeholder=" "
											name="partenza_aereo_treno"
											id="departureTime"
											required
										/>
										<label className="form__label">
											{t("contact.fields.departure_time.label")}{" "}
											{destinationTransfer === "Aeroporto"
												? `${t("contact.fields.departure_time.airplane")}`
												: `${t("contact.fields.departure_time.train")}`}{" "}
											*
										</label>
									</div>
								)}
							</div>
							{destinationTransfer === "Porto" && (
								<div className="row mt-1">
									<div
										className={`form-group  ${
											destinationTransfer === "porto" ? "w-100" : ""
										}`}
									>
										<select
											className="select-form-group form-control"
											aria-label="Select Destination"
											name="imbarco"
											required
											defaultValue=""
										>
											<option hidden value="">
												{t("contact.fields.embark.placeholder")}
											</option>
											<option
												value="Croceria"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_one")}
											</option>
											<option
												value="Capraia"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_two")}
											</option>
											<option
												value="GolfoAranci"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_three")}
											</option>
											<option
												value="Gorgona"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_ten")}
											</option>
											<option
												value="Olbia"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_four")}
											</option>

											<option
												value="Palermo"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_five")}
											</option>
											<option
												value="Bastia"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_six")}
											</option>
											<option
												value="Ile Rousse"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_seven")}
											</option>
											<option
												value="PortoVecchio"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_eight")}
											</option>
											<option
												value="Altro Imbarco"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.option_nine")}
											</option>
										</select>
										<label className="form__label">
											{t("contact.fields.embark.label")} *
										</label>
									</div>

									<div className="form-group ">
										<input
											className="form-control"
											type="time"
											placeholder=" "
											name="partenza_traghetto"
											id="departureTime"
											required
										/>
										<label className="form__label">
											{t("contact.fields.departure_time.label")} *
										</label>
									</div>
								</div>
							)}
						</>
					)}

					<div
						className="form-group mt-1 mb-2"
						style={{ height: "fit-content" }}
					>
						<textarea
							className="form-control"
							name="message"
							rows="5"
							placeholder={t("contact.fields.message.placeholder")}
						></textarea>
						<label className="form__label">
							{t("contact.fields.message.label")}
						</label>
					</div>

					<div className="d-flex align-items-center pb-2">
						<input
							className="form-check-input mt-0"
							style={{
								marginRight: "0.5rem",
								aspectRatio: "1",
							}}
							type="checkbox"
							name="personalData"
							value="isAccepted"
							id="personalData"
							required
						/>
						<label className="form-check-label" htmlFor="personalData">
							{t("contact.fields.acceptance_data.text")}{" "}
							<a
								href={
									currentLanguage === "it"
										? "document/Privacy_ParkingTransferLivorno.pdf"
										: "document/Privacy_ParkingTransferLivorno_EN.pdf"
								}
								target="_blank"
								rel="noreferrer"
							>
								{t("contact.fields.acceptance_data.link")}
							</a>
							*
						</label>
					</div>
					<div className="text-center mt-2">
						<button type="submit">
							{inquiryObject === "generalInfo"
								? `${t("contact.button_message")}`
								: `${t("contact.button_book")}`}
						</button>
					</div>
				</form>
			</div>
		</>
	);
}
