import React from "react";
import Home from "./components/home";
import Ferries from "./components/ferries";
import { Route, Routes } from "react-router-dom";
import { Topbar } from "./components/topbar";
import { Header } from "./components/header";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import i18next from "i18next";

function initializeReactGA() {
	ReactGA.initialize("G-RGQ333J5DR");
	ReactGA.pageview(window.location.pathname + window.location.search);
}

export default function App() {
	initializeReactGA();

	const [t, i18n] = useTranslation("common");
	function changeLanguage(lng) {
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
	}

	const currentLanguage = !localStorage.getItem("lng")
		? i18next.language
		: localStorage.getItem("lng");

	return (
		<>
			<Topbar
				changeLanguage={changeLanguage}
				currentLanguage={currentLanguage}
			/>

			{/*  Header */}
			<Header t={t} />
			<Routes>
				<Route path="/" element={<Home t={t} />} exact />
				<Route path="/home" element={<Home t={t} />} />
				<Route path="/ferry&cruise" element={<Ferries t={t} />} />
			</Routes>
		</>
	);
}
