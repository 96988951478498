import React from "react";
export function Faq({ t }) {
	return (
		<section id="faq" className="faq sections-bg">
			<div className="container" data-aos="fade-up">
				<div className="row gy-4">
					<div className="col-lg-4">
						<div className="content px-xl-5">
							<h3>
								Frequently Asked <strong>Questions</strong>
							</h3>
							{/* <p className="text-white">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
								aute irure dolor in reprehenderit
							</p> */}
						</div>
					</div>

					<div className="col-lg-8">
						<div
							className="accordion"
							id="faqlist"
							data-aos="fade-up"
							data-aos-delay="100"
						>
							<div className="accordion--item">
								<h3 className="accordion-header">
									<button
										className="accordion-button accordion-button-first collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#faq-content-1"
									>
										<span className="num">1.</span>
										{t("faq.one.q")}
									</button>
								</h3>
								<div
									id="faq-content-1"
									className="accordion-collapse collapse"
									data-bs-parent="#faqlist"
								>
									<div className="accordion-body">
										<p className="mb-0">{t("faq.one.a")}</p>
									</div>
								</div>

								{/*  */}

								<div className="accordion--item">
									<h3 className="accordion-header">
										<button
											className="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#faq-content-2"
										>
											<span className="num">2.</span>
											{t("faq.two.q")}
										</button>
									</h3>
									<div
										id="faq-content-2"
										className="accordion-collapse collapse"
										data-bs-parent="#faqlist"
									>
										<div className="accordion-body">
											<p className="mb-0">{t("faq.two.a")}</p>
										</div>
									</div>
								</div>
								{/* <!-- # Faq item--> */}

								<div className="accordion--item">
									<h3 className="accordion-header">
										<button
											className="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#faq-content-3"
										>
											<span className="num">3.</span>
											{t("faq.three.q")}
										</button>
									</h3>
									<div
										id="faq-content-3"
										className="accordion-collapse collapse"
										data-bs-parent="#faqlist"
									>
										<div className="accordion-body">
											<p className="mb-0">{t("faq.three.a")}</p>
										</div>
									</div>
								</div>
								{/* <!-- # Faq item--> */}

								<div className="accordion--item">
									<h3 className="accordion-header">
										<button
											className="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#faq-content-4"
										>
											<span className="num">4.</span>
											{t("faq.four.q")}
										</button>
									</h3>
									<div
										id="faq-content-4"
										className="accordion-collapse collapse"
										data-bs-parent="#faqlist"
									>
										<div className="accordion-body">
											<p className="mb-0">{t("faq.four.a")}</p>
										</div>
									</div>
								</div>
								{/* <!-- # Faq item--> */}

								<div className="accordion--item">
									<h3 className="accordion-header">
										<button
											className="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#faq-content-5"
										>
											<span className="num">5.</span>
											{t("faq.five.q")}
										</button>
									</h3>
									<div
										id="faq-content-5"
										className="accordion-collapse collapse"
										data-bs-parent="#faqlist"
									>
										<div className="accordion-body ">
											<p className="mb-0">{t("faq.five.a")}</p>
										</div>
									</div>
								</div>
								{/* <!-- # Faq item--> */}
								<div className="accordion--item">
									<h3 className="accordion-header">
										<button
											className="accordion-button accordion-button-last collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#faq-content-6"
										>
											<span className="num">6.</span>
											{t("faq.six.q")}
										</button>
									</h3>
									<div
										id="faq-content-6"
										className="accordion-collapse collapse"
										data-bs-parent="#faqlist"
									>
										<div className="accordion-body accordion-body-last">
											<p className="mb-0">{t("faq.six.a")}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
