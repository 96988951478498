import React from "react";
import { Link } from "react-router-dom";
export function Pricing({ t }) {
	return (
		<section id="pricing" className="pricing sections-white">
			<div className="container" data-aos="fade-up">
				<div className="section-header pb-4 pb-lg-2">
					<h2>{t("rates.title")}</h2>
					<p>{t("rates.description")}</p>
				</div>

				<div
					className="row g-4 py-lg-5"
					data-aos="zoom-out"
					data-aos-delay="100"
				>
					<div className="col-lg-4">
						<div className="pricing-item background-moto">
							<h3 className="text-dark">{t("rates.moto")}</h3>
							<div className="icon">
								<div className="icon">
									<img
										src="assets/img/moto-bg.png"
										className="img-fluid w-75"
										alt=""
										data-aos="zoom-out"
										data-aos-delay="100"
									/>
								</div>
							</div>
							<h6 className="text-center">
								<span> {t("rates.price_from")} </span>
							</h6>
							<h4 className="text-dark">
								<sup>€</sup>10
								<span className="text-dark"> / {t("rates.price")}</span>
							</h4>
							<ul className="text-dark">
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.one")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.two")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.three")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.four")}
								</li>
								{/*! Riabilitare sconti se necessario  */}
								{/* <li style={{ fontWeight: "700" }}>
									<i className="fi fi-rs-sack-dollar"></i>{" "}
									{t("rates.benefits.five")}
									{""} 60 €
								</li> */}
							</ul>
							<div className="text-center">
								<Link to="/home#contact" className="buy-btn">
									{t("rates.button_book")}
								</Link>
							</div>
						</div>
					</div>
					{/* <!-- End Pricing Item --> */}

					<div className="col-lg-4">
						<div className="pricing-item featured background-car">
							<h3 className="text-dark">{`${t('rates.auto')}/${t('rates.trailer')}`}</h3>
							<div className="icon">
								<img
									src="assets/img/tesla-bg.png"
									className="img-fluid"
									alt=""
									data-aos="zoom-out"
									data-aos-delay="100"
								/>
							</div>

							<h6 className="text-center">
								<span> {t("rates.price_from")} </span>
							</h6>
							<h4 className="text-dark">
								<sup> €</sup>15
								<span className="text-dark"> / {t("rates.price")}</span>
							</h4>
							<ul className="text-dark">
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.one")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.two")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.three")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.four")}
								</li>
								{/*! Riabilitare sconti se necessario  */}
								{/* <li style={{ fontWeight: "700" }}>
									<i className="fi fi-rs-sack-dollar"></i>{" "}
									{t("rates.benefits.five")}
									{""} 90 €
								</li> */}
							</ul>
							<div className="text-center">
								<Link to="/home#contact" className="buy-btn">
									{t("rates.button_book")}
								</Link>
							</div>
						</div>
					</div>
					{/* <!-- End Pricing Item --> */}

					<div className="col-lg-4">
						<div className="pricing-item background-rv">
							<h3 className="text-dark">{t("rates.camper")}</h3>
							<div className="icon">
								<img
									src="assets/img/rv-bg.png"
									className="img-fluid w-75"
									alt=""
									data-aos="zoom-out"
									data-aos-delay="100"
								/>
							</div>
							<h6 className="text-center">
								<span> {t("rates.price_from")} </span>
							</h6>
							<h4 className="text-dark">
								<sup>€</sup>20
								<span className="text-dark"> / {t("rates.price")}</span>
							</h4>
							<ul className="text-dark">
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.one")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.two")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.three")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.four")}
								</li>
								{/*! Riabilitare sconti se necessario  */}
								{/* <li style={{ fontWeight: "700" }}>
									<i className="fi fi-rs-sack-dollar"></i>{" "}
									{t("rates.benefits.five")}
									{""} 120 €
								</li> */}
							</ul>
							<div className="text-center">
								<Link to="/home#contact" className="buy-btn">
									{t("rates.button_book")}
								</Link>
							</div>
						</div>
					</div>
					{/* <!-- End Pricing Item --> */}
				</div>
				<div className="section-header pb-4 pb-lg-2 pt-5">
					<h2 className="mb-md-0">{t("rates.additional_services.title")}</h2>
				</div>
				<div
					className="row g-4 py-lg-5"
					data-aos="zoom-out"
					data-aos-delay="100"
				>
					<div className="col-lg-6">
						<div className="pricing-item pricing-item-white-bg">
							<h3>{t("rates.additional_services.one")}</h3>

							<div className="icon">
								<i className="fi fi-rs-car-wash"></i>
							</div>

							<h4>
								<sup>€</sup>20<span> / {t("rates.price_from")} </span>
							</h4>

							<div className="text-center mt-4">
								<Link to="/home#carWash">{t("rates.button_find_more")}</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="pricing-item pricing-item-white-bg">
							<h3>{t("rates.additional_services.two")}</h3>

							<div className="icon">
								<i className="fi fi-rs-car-mechanic"></i>
							</div>

							<h4>
								<sup>€</sup>40<span> / {t("rates.price_from")} </span>
							</h4>

							<div className="text-center mt-4">
								<Link to="/home#mechanicAssistance">{t("rates.button_find_more")}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
