import React, { useState } from "react";
import MediaQuery from "../helpers/media-query";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

export function Header({ t }) {
	const [navOpen, setNavOpen] = useState(false);

	function onChange() {
		setNavOpen(!navOpen);
		ReactGA.event({
			category: "Esempio",
			action: "Click",
			label: "Test evento personalizzato",
		});
	}
	function closeNav() {
		setNavOpen(false);
	}
	const { isNotDesktop } = MediaQuery();
	const nav_open = navOpen ? "nav_open" : null;

	const navbarCN = isNotDesktop ? "nav" : "navbar";
	const navbarUlCN = isNotDesktop
		? navOpen
			? "nav__list"
			: "nav_list_closed nav__list"
		: null;
	const navbarListCN = isNotDesktop ? "nav__item" : null;
	const navbarListAnchorCN = isNotDesktop ? "nav__link" : null;

	return (
		<header
			className={`${nav_open} header d-flex align-items-center`}
			id="header"
		>
			<div className="container-fluid container d-flex align-items-center justify-content-between">
				<Link to="/home#hero" className="logo d-flex align-items-center">
					{/* Uncomment the line below if you also wish to use an image logo  */}
					{/* <img src={logo} alt="" style={{ width: "300px" }} /> */}
					<h1>Parking & Transfer Livorno</h1>
				</Link>

				{isNotDesktop && (
					<div className="nav-toogle-container">
						<button
							className="nav-toogle"
							aria-label="toogle navigation"
							onClick={onChange}
						>
							<span className="hamburger"></span>
						</button>
					</div>
				)}

				<nav className={`${navbarCN}`} id="navbar">
					<ul className={`${navbarUlCN}`} onClick={closeNav}>
						<li className={`${navbarListCN}`}>
							<Link to="/home" className={`${navbarListAnchorCN}`}>
								Home
							</Link>
						</li>
						<li className={`${navbarListCN}`}>
							<Link to="/home#services" className={`${navbarListAnchorCN}`}>
								{t("header.services")}
							</Link>
						</li>
						<li>
							<Link to="/ferry&cruise" className={`${navbarListAnchorCN}`}>
								{t("header.ferries")}
							</Link>
						</li>
						<li className={`${navbarListCN}`}>
							<Link to="/home#pricing" className={`${navbarListAnchorCN}`}>
								{t("header.pricing")}
							</Link>
						</li>
						<li className={`${navbarListCN}`}>
							<Link to="/home#faq" className={`${navbarListAnchorCN}`}>
								FAQ
							</Link>
						</li>
						<li className={`${navbarListCN}`}>
							<Link to="/home#contact" className={`${navbarListAnchorCN}`}>
								{t("header.booking")}
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
}
