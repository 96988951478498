import React from "react";
import { logoNoBcg } from "../media/all-photo-gallery";
import { Link } from "react-router-dom";
export function Hero({ t }) {
	return (
		<section id="hero" className="hero">
			<div className="cover">
				<div className="container main-info position-relative">
					<div className="row gy-5" data-aos="fade-in">
						<div className="col-lg-8 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start mt-0">
							{/* <h2>Parking & Transfer Livorno</h2>
							<h6>by M&M Srl </h6> */}
							<img src={logoNoBcg} alt="logo" />

							<p>{t("hero.description")}</p>
							<div className="d-flex justify-content-center justify-content-lg-start">
								<Link to="/home#contact" className="btn-get-started">
									{t("header.cta.one")}
								</Link>
								<Link to="/home#pricing"
									className="glightbox btn-see-rates d-flex align-items-center">
									<i className="bi bi-tags"></i>
									<span>{t("header.cta.two")}</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="benefitBoxes">
				<div className="icon-boxes ">
					<div className="container">
						<div className="row gy-4 mt-5 justify-content-center">
							<div
								className="col-xl-3 col-md-6"
								data-aos="fade-up"
								data-aos-delay="100"
							>
								<div className="icon-box">
									<div className="icon">
										<i className="fi fi-rs-shield-check"></i>
									</div>
									<h4 className="title">
										<Link to="/home#services" className="stretched-link">
											{t("header.benefits.one")}
										</Link>
									</h4>
								</div>
							</div>
							{/* <!--End Icon Box --> */}
							<div
								className="col-xl-3 col-md-6"
								data-aos="fade-up"
								data-aos-delay="200"
							>
								<div className="icon-box">
									<div className="icon">
										<i className="fi fi-rs-video-camera-alt"></i>
									</div>
									<h4 className="title">
										<Link to="/home#services" className="stretched-link">
											{t("header.benefits.five")}
										</Link>
									</h4>
								</div>
							</div>

							<div
								className="col-xl-3 col-md-6"
								data-aos="fade-up"
								data-aos-delay="200"
							>
								<div className="icon-box">
									<div className="icon">
										<i className="fi fi-rs-time-twenty-four"></i>
									</div>
									<h4 className="title">
										<Link to="/home#services" className="stretched-link">
											{t("header.benefits.two")}
										</Link>
									</h4>
								</div>
							</div>
							{/* <!--End Icon Box --> */}

							<div
								className="col-xl-3 col-md-6"
								data-aos="fade-up"
								data-aos-delay="300"
							>
								<div className="icon-box">
									<div className="icon">
										<i className="fi fi-rs-marker"></i>
									</div>
									<h4 className="title">
										<Link to="/home#services" className="stretched-link">
											{t("header.benefits.three")}
										</Link>
									</h4>
								</div>
							</div>
							{/* <!--End Icon Box --> */}

							{/* <!--End Icon Box --> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
