import React from "react";
import { Link } from "react-router-dom";
export function Services({ t }) {
	return (
		<section id="services" className="services sections-bg">
			<div className="container" data-aos="fade-up">
				<div className="section-header section-header-blue">
					<h2 className="text-white">{t("benefits.title")}</h2>
					<p>{t("benefits.description")}</p>
				</div>

				<div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
					<div className="col-lg-4 col-md-6">
						<div className="service-item  position-relative">
							<div className="icon">
								<i className="fi fi-rs-calendar-check"></i>
							</div>
							<h3>{t("benefits.one.title")}</h3>
							<p>{t("benefits.one.description")}</p>
							<Link to="/home#contact" className="readmore stretched-link">
								{t("benefits.cta")} <i className="bi bi-arrow-right"></i>
							</Link>
						</div>
					</div>
					{/* <!-- End Service Item --> */}

					<div className="col-lg-4 col-md-6">
						<div className="service-item position-relative">
							<div className="icon">
								<i className="fi fi-rs-comment-xmark"></i>
							</div>
							<h3>{t("benefits.two.title")}</h3>
							<p>{t("benefits.two.description")}</p>
							<Link to="/home#contact"  className="readmore stretched-link">
								{t("benefits.cta")} <i className="bi bi-arrow-right"></i>
							</Link>
						</div>
					</div>
					{/* <!-- End Service Item --> */}

					<div className="col-lg-4 col-md-6">
						<div className="service-item position-relative">
							<div className="icon">
								<i className="fi fi-rs-shuttle-van"></i>
							</div>
							<h3>{t("benefits.three.title")}</h3>
							<p>{t("benefits.three.description")}</p>
							<Link to="/home#contact"  className="readmore stretched-link">
								{t("benefits.cta")} <i className="bi bi-arrow-right"></i>
							</Link>
						</div>
					</div>
					{/* <!-- End Service Item --> */}

					<div className="col-lg-4 col-md-6" id="carWash">
						<div className="service-item position-relative">
							<div className="icon">
								<i className="fi fi-rs-car-wash"></i>
							</div>
							<h3>{t("benefits.four.title")}</h3>
							<p>{t("benefits.four.description")}</p>
							<Link to="/home#contact"  className="readmore stretched-link">
								{t("benefits.cta")} <i className="bi bi-arrow-right"></i>
							</Link>
						</div>
					</div>
					{/* <!-- End Service Item --> */}

					<div className="col-lg-4 col-md-6" id="mechanicAssistance">
						<div className="service-item position-relative">
							<div className="icon">
								<i className="fi fi-rs-car-mechanic"></i>
							</div>
							<h3>{t("benefits.five.title")}</h3>
							<p>{t("benefits.five.description")}</p>
							<Link to="/home#contact" className="readmore stretched-link">
								{t("benefits.cta")} <i className="bi bi-arrow-right"></i>
							</Link>
						</div>
					</div>
					{/* <!-- End Service Item --> */}

					<div className="col-lg-4 col-md-6">
						<div className="service-item position-relative">
							<div className="icon">
								<i className="fi fi-rs-handshake"></i>
							</div>
							<h3>{t("benefits.six.title")}</h3>
							<p>{t("benefits.six.description")}</p>
							<Link to="/home#contact"  className="readmore stretched-link">
								{t("benefits.cta")} <i className="bi bi-arrow-right"></i>
							</Link>
						</div>
					</div>
					{/* <!-- End Service Item --> */}
				</div>
			</div>
		</section>
	);
}
