import React from "react";
import "./tooltip.css";
import MediaQuery from "../helpers/media-query";
import { flagIT, flagUK } from "../media/all-photo-gallery";
export function Topbar({ changeLanguage, currentLanguage }) {
	const { isMobile, isMobileMax } = MediaQuery();

	return (
		<section id="topbar" className="topbar d-flex align-items-center">
			<div className="container d-flex justify-content-between">
				<div className="contact-info d-flex align-items-center">
					<div className="toolTip">
						<a
							href="mailto:info@parkingtransferlivorno.com"
							style={{ textDecoration: "none" }}
						>
							<i className="fi fi-rs-envelope-open-text d-flex align-items-center">
								<span
									style={{
										display: isMobile && "none",
										fontSize: isMobileMax ? "11px" : "14px",
									}}
								>
									info@parkingtransferlivorno.com
								</span>
							</i>
						</a>
					</div>
					<div className="toolTip ms-4">
						<a href="tel:+39 375 696 6969" style={{ textDecoration: "none" }}>
							<i className="fi fi-rs-phone-call d-flex align-items-center ">
								<span
									style={{
										display: isMobile && "none",
										fontSize: isMobileMax ? "11px" : "14px",
									}}
								>
									+39 375 696 6969
								</span>
							</i>
						</a>
					</div>
				</div>
				<div className="d-flex">
					<div className="mb-0">
						{currentLanguage === "it" ? (
							<span
								onClick={() => {
									changeLanguage("en");
								}}
								style={{ cursor: "pointer" }}
							>
								<img
									src={flagUK}
									alt="italian flag"
									style={{ height: "16px", paddingRight: "4px" }}
								/>
								<span style={{ fontSize: "12px" }}>EN</span>
							</span>
						) : (
							<span
								onClick={() => {
									changeLanguage("it");
								}}
								style={{ cursor: "pointer" }}
							>
								<img
									src={flagIT}
									alt="italian flag"
									style={{ height: "16px", paddingRight: "4px" }}
								/>
								<span style={{ fontSize: "12px" }}>IT</span>
							</span>
						)}
					</div>
					<div className="social-links d-flex align-items-center">
						<a
							href="https://instagram.com/mm_parking?igshid=YmMyMTA2M2Y="
							rel="noreferrer"
							target="_blank"
							className="instagram"
						>
							<i className="bi bi-instagram ms-2"></i>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}
